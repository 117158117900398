<template>
  <thead
    class="caption-xs d-block"
  >
    <tr>
      <slot name="corner" />

      <th
        v-translate
        translate-context="MDateHeader component abbreviation"
        style="top: 0;"
      >
        mo
      </th>

      <th
        v-for="(month, i) in months"
        :key="i"
        :colspan="month.colspan"
        style="top: 0;"
      >
        {{ month.month }}
      </th>
    </tr>

    <tr>
      <th
        v-translate
        translate-context="MDateHeader component abbreviation"
        style="top: 24px;"
      >
        wk
      </th>

      <th
        v-for="(week, i) in weeks"
        :key="i"
        :colspan="week.colspan"
        style="top: 24px;"
      >
        {{ week.week }}
      </th>
    </tr>

    <tr>
      <th
        v-translate
        translate-context="MDateHeader component abbreviation"
        style="top: 48px;"
      >
        d
      </th>

      <th
        v-for="(day, i) in days"
        :key="i"
        style="top: 48px;"
        :class="{
          highlight: highlight(i),
        }"
      >
        <div
          :class="{
            'sequence-jump': i >= 0 && i < (days.length - 1) &&
              Number.parseInt(day, 10) + 1 !== Number.parseInt(days[i + 1], 10)
          }"
        >
          {{ day }}
        </div>
      </th>
    </tr>

    <slot name="append" />

    <tr>
      <slot name="titles" />

      <th
        v-for="(takt, i) in taktIndexes"
        :key="i"
        :style="{
          top: $slots.append ? '96px' : '72px',
          position: 'absolute',
          left: `${paddingFromLeft(takt)}px`,
          width: `${cellWidth}px`,
          maxWidth: 'none',
        }"
        :colspan="project.tactDuration"
        :class="{
          highlight: hoverIndex === i,
        }"
      >
        {{ takt }}
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    props: {
      paddingFromStart: {
        type: Number,
        required: true,
      },

      cellWidth: {
        type: Number,
        required: true,
      },

      hoverIndex: {
        type: Number,
        default: null,
      },

      highlight: {
        type: Function,
        default: () => false,
      },

      project: {
        type: Object,
        required: true,
      },

      takts: {
        type: Array,
        required: true,
      },
    },

    computed: {
      taktIndexes() {
        return [...new Set(this.takts.map(ts => ts.taktIndex))];
      },

      calendar() {
        const { workingDates: dates } = this.project;
        const calendarData = new Map();
        const weeksCalendar = new Map();

        for (let i = 0; i <= dates.length - 1; i += 1) {
          /**
           * This can be used to exclude dates such as weekends.
           * If using the weekday() method, remember to uncomment
           * the weekday plugin from day.js.
           */
          // const isWeekend = startsAt.weekday() === 5 || startsAt.weekday() === 6;
          // if (!isWeekend) {}

          const startsAt = this.$dayjs(dates[i]);
          const year = startsAt.format('YYYY');
          const month = startsAt.format('M');
          const week = startsAt.week();
          const day = startsAt.format('D');

          if (!calendarData.has(year)) calendarData.set(year, new Map());
          if (!calendarData.get(year).has(month)) calendarData.get(year).set(month, []);
          calendarData.get(year).get(month).push(day);
          if (!weeksCalendar.has(year)) weeksCalendar.set(year, new Map());
          if (!weeksCalendar.get(year).has(week)) weeksCalendar.get(year).set(week, []);
          weeksCalendar.get(year).get(week).push(day);
        }
        return {calendarData, weeksCalendar};
      },

      dateColumns() {
        const { calendar: {calendarData, weeksCalendar} } = this;

        let datesData = [];
        const monthsData = [];
        const yearsData = [];
        const weeksData = [];

        calendarData.forEach((months, year) => {
          let monthColSpan = 0;

          months.forEach((days, month) => {
            let monthColSpan = 0;
            monthColSpan += days.length;
            datesData = [
                ...datesData,
                ...days,
              ];
            monthsData.push({
              month,
              colspan: monthColSpan,
            });
          });

          yearsData.push({
            year,
            colspan: monthColSpan,
          });
        });

        // We need to do weeks per year because we might have multiple
        // years in the schedule.
        weeksCalendar.forEach((weeks, year) => {
          weeks.forEach((days, week) => {
            let weekColSpan = 0;
            weekColSpan += days.length;
            weeksData.push({
              week,
              colspan: weekColSpan,
            });
          });
        });

        return {
          years: yearsData,
          months: monthsData,
          weeks: weeksData,
          days: datesData,
        };
      },

      years() {
        return this.dateColumns.years;
      },

      months() {
        return this.dateColumns.months;
      },

      weeks() {
        return this.dateColumns.weeks;
      },

      days() {
        return this.dateColumns.days;
      },
    },

    methods: {
      paddingFromLeft(takt) {
        const cellPosLeft = this.cellWidth * (takt - 1);
        return this.paddingFromStart + cellPosLeft;
      },
    },
  };
</script>

<style lang="scss" scoped>
.sequence-jump {
  border-right: 3px solid var(--v-backgroundAccent-darken1);;
}
</style>
