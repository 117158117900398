var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{directives:[{name:"hotkey",rawName:"v-hotkey",value:({ 'L': _vm.passageEditor }),expression:"{ 'L': passageEditor }"}],staticStyle:{"display":"none"}}),_c('div',{directives:[{name:"hotkey",rawName:"v-hotkey",value:({ 'T': _vm.teamEditor }),expression:"{ 'T': teamEditor }"}],staticStyle:{"display":"none"}}),_c('div',{directives:[{name:"hotkey",rawName:"v-hotkey",value:({ 'P': _vm.wpEditor }),expression:"{ 'P': wpEditor }"}],staticStyle:{"display":"none"}}),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.passages,"label":_vm.selectPassagePlaceholder,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":"","menu-props":"offsetY, closeOnContentClick"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{on:{"click":function () { return _vm.editPassage(); }}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" add ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Create passage ")]),_c('v-list-item-subtitle',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Keyboard shortcut"}},[_vm._v(" Shortcut L ")])],1)],1),_c('v-divider')]},proxy:true},{key:"item",fn:function(ref){
var passage = ref.item;
return [_c('v-list-item',{attrs:{"input-value":_vm.selectedPassageId === passage.id},on:{"click":function($event){_vm.selectedPassageId = passage.id}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(passage.name)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.editPassage(passage.id); }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)],1)],1)]}}]),model:{value:(_vm.selectedPassageId),callback:function ($$v) {_vm.selectedPassageId=$$v},expression:"selectedPassageId"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._g({staticClass:"mx-4",attrs:{"disabled":!_vm.selectedPassageId,"small":"","outlined":""},on:{"click":function () { return _vm.editTeam(); }}},on),[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Create team ")])])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.createTeamTooltip)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._g({attrs:{"disabled":!_vm.selectedPassageId || _vm.teams.length <= 0,"small":"","outlined":""},on:{"click":function () { return _vm.editWorkPackage(); }}},on),[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Create work package ")])])],1)]}}])},[_c('span',[(!_vm.selectedPassageId)?[_vm._v(" Select a passage first ")]:(_vm.teams.length <= 0)?[_vm._v(" Create teams first ")]:[_vm._v(" Shortcut P ")]],2)]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" zoom_in ")])],1)]}}]),model:{value:(_vm.zoomDropDown),callback:function ($$v) {_vm.zoomDropDown=$$v},expression:"zoomDropDown"}},[_c('v-card',[_c('v-card-text',[_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Table zoom control"}},[_vm._v(" Horizontal ")]),_c('v-btn',{attrs:{"disabled":_vm.CARD_WIDTH <= _vm.MIN_CARD_WIDTH,"icon":""},on:{"click":function () { return _vm.changeCardSize({ dx: -1 }); }}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v(" remove ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.changeCardSize({ dx: 1 }); }}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v(" add ")])],1),_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"mt-12",attrs:{"translate-context":"Table zoom control"}},[_vm._v(" Vertical ")]),_c('v-btn',{attrs:{"disabled":_vm.CARD_HEIGHT <= _vm.MIN_CARD_HEIGHT,"icon":""},on:{"click":function () { return _vm.changeCardSize({ dy: -1 }); }}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v(" remove ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.changeCardSize({ dy: 1 }); }}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v(" add ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex",staticStyle:{"height":"calc(100vh - 144px)","overflow":"auto"}},[(!_vm.selectedPassageId)?_c('m-tip-note',{attrs:{"title":_vm.noChosenPassageTipTitle,"tip":_vm.noChosenPassageTip}}):[_c('div',{staticStyle:{"position":"sticky","left":"0","z-index":"2"}},[_c('div',{style:({
            height: (_vm.CARD_HEIGHT + "px"),
          })},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"pa-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","block":"","tile":"","height":"100%"},on:{"click":_vm.openReOrderingDialog}},tooltip),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" low_priority ")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Passage table"}},[_vm._v(" sort ")])],1)]}}])},[_c('v-card',[_c('v-card-text',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Sort packages tooltip [1/2]"}},[_vm._v(" Here you can sort work ")]),_c('br'),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Sort packages tooltip [2/2]"}},[_vm._v(" packages to another order. ")])])],1)],1)],1),_c('div',{staticClass:"d-flex flex-column align-end"},_vm._l((_vm.workPackages),function(wp,i){return _c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"wp-card d-flex flex-column resizable relative",attrs:{"width":_vm.MIN_CARD_WIDTH,"ripple":false,"tile":"","color":"backgroundAccent lighten-1","elevation":"6","data-wp-id":wp.id,"data-row-count":wp.rowCount}},[_c('div',{style:({
                  position: 'absolute',
                  bottom: 0,
                  left: '100%',
                  width: (((_vm.teams.length + 1) * _vm.CARD_WIDTH) + "px"),
                  borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                  pointerEvents: 'none',
                })}),(hover)?_c('div',{staticClass:"d-flex align-center px-1",style:({
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1,
                })},[_c('v-btn',{staticClass:"secondary",attrs:{"icon":"","small":"","dark":""},on:{"click":function () { return _vm.editWorkPackage(wp.id); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center align-center full-height caption font-weight-bold"},[_vm._v(" "+_vm._s(wp.name)+" ")]),_c('div',{staticClass:"resize-bottom full-width",style:({
                  position: 'absolute',
                  height: '12px',
                  right: 0,
                  bottom: '-6px',
                  left: 0,
                  zIndex: 1,
                })})])]}}],null,true)})}),1)]),_c('div',{staticClass:"full-width",staticStyle:{"display":"table"}},[(_vm.teams.length <= 0)?_c('m-tip-note',{attrs:{"title":_vm.noTeamsTipTitle,"tip":_vm.noTeamsTip}}):[_c('div',{staticClass:"white-space-nowrap",staticStyle:{"position":"sticky","top":"0","z-index":"2"},style:({
              height: (_vm.CARD_HEIGHT + "px"),
            })},[_c('div',{staticClass:"team-card draggable",style:({
                position: 'absolute',
                top: 0,
                left: 0,
                transform: ("translate(" + (0 * _vm.CARD_WIDTH) + "px)"),
              }),attrs:{"data-order":0,"data-coord-x":0,"data-pos-x":0 * _vm.CARD_WIDTH}},[_c('v-card',{staticClass:"overflow-hidden",attrs:{"width":_vm.CARD_WIDTH,"height":_vm.CARD_HEIGHT,"ripple":false,"tile":"","color":"backgroundAccent","elevation":"3","data-column-index":_vm.i}},[_c('div',{staticClass:"d-flex justify-center align-center overflow-hidden\n                  caption-xs font-weight-bold full-height text-center drag-handle"},[_vm._v(" NO TEAM ")])]),_c('div',{style:({
                  position: 'absolute',
                  top: '100%',
                  left: '100%',
                  marginLeft: '-1px',
                  height: (_vm.dropzoneHeight + "px"),
                  borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
                  pointerEvents: 'none',
                })})],1),_vm._l((_vm.teams),function(team,i){return _c('div',{key:i,ref:("team-" + (team.id)),refInFor:true,staticClass:"team-card draggable",style:({
                position: 'absolute',
                top: 0,
                left: 0,
                transform: ("translate(" + ((team.order + 1) * _vm.CARD_WIDTH) + "px)"),
              }),attrs:{"data-order":team.order + 1,"data-coord-x":(team.order + 1),"data-pos-x":(team.order + 1) * _vm.CARD_WIDTH,"data-team-id":team.id}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{staticClass:"overflow-hidden",attrs:{"width":_vm.CARD_WIDTH,"height":_vm.CARD_HEIGHT,"ripple":false,"tile":"","color":"backgroundAccent lighten-1","elevation":"3","data-column-index":i}},[(hover)?_c('div',{staticClass:"d-flex align-center px-1",style:({
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 1,
                    })},[_c('v-btn',{staticClass:"secondary",attrs:{"icon":"","small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.editTeam(team.id); }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center align-center overflow-hidden\n                    caption-xs font-weight-bold full-height text-center drag-handle"},[_vm._v(" "+_vm._s(team.name)+" ")])])]}}],null,true)}),_c('div',{style:({
                  position: 'absolute',
                  top: '100%',
                  left: '100%',
                  marginLeft: '-1px',
                  height: (_vm.dropzoneHeight + "px"),
                  borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
                  pointerEvents: 'none',
                })})],1)})],2),(_vm.workPackages.length <= 0)?_c('m-tip-note',{attrs:{"title":_vm.noWorkPackagesTipTitle,"tip":_vm.noWorkPackagesTip}}):_c('div',{staticClass:"dropzone relative",style:({
              width: ((_vm.CARD_WIDTH * (_vm.teams.length + 1)) + "px"),
              height: (_vm.dropzoneHeight + "px"),
              marginRight: '1px',
              marginBottom: '1px',
            })},[_c('div',{staticClass:"d-flex justify-center align-center caption font-italic backgroundAccent--text",style:({
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }),on:{"click":_vm.onDropzoneClick}},[(_vm.tasks.length <= 0)?_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"text-center",staticStyle:{"pointer-events":"none"},attrs:{"translate-context":"Passage table"}},[_vm._v(" Click on a cell to create a task ")]):_vm._e()]),_vm._l((_vm.tasks),function(task,i){return _c('v-card',{key:i,staticClass:"task-card draggable",staticStyle:{"position":"absolute"},attrs:{"width":_vm.CARD_WIDTH,"height":_vm.CARD_HEIGHT,"ripple":false,"tile":"","dark":task.hex !== '#FFFFFF',"data-order":task.order,"data-coord-x":task.x == null ? 0 : task.x + 1,"data-coord-y":task.y,"data-task-id":task.id,"data-wp-id":task.workPackageId,"color":task.hex},on:{"click":function($event){}}},[(task.requiresSupervision)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var tooltip = ref.on;
return [_c('v-icon',_vm._g({style:({
                      position: 'absolute',
                      bottom: '-6px',
                      right: '-6px',
                      zIndex: 1,
                    }),attrs:{"color":"accent"}},tooltip),[_vm._v(" pan_tool ")])]}}],null,true)},[_c('span',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Pace-setting task tooltip [1/3]"}},[_vm._v(" Pace-setting task. ")]),_c('br'),_c('br'),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Pace-setting task tooltip [2/3]"}},[_vm._v(" This task has to be completed ")]),_c('br'),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Pace-setting task tooltip [3/3]"}},[_vm._v(" before following tasks can be started. ")])])]):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('div',{staticClass:"d-flex full-height relative"},[_c('div',{staticClass:"flex-grow-1 drag-handle caption-xxs d-flex align-top",style:({
                      padding: '2px',
                      overflow: 'hidden',
                    }),on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"d-flex flex-column pl-1 pt-1"},[_vm._v(" "+_vm._s(/* card content */)+" "),_c('div',{staticClass:"task-name",style:({
                        overflowY: 'hidden',
                    })},[_vm._v(" "+_vm._s(task.name)+" ")]),_c('div',{staticClass:"task-attributes flex-grow-1 d-flex flex-row"},[_vm._v(" "+_vm._s(/* Estimated duration */)+" "),_c('div',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" timer ")]),(task.estimatedDuration > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.humanReadableHourDuration(task.estimatedDuration))+" ")]):_c('span',[_vm._v(" -- ")])],1),_vm._v(" "+_vm._s(/* Waiting time */)+" "),(task.waitingDuration > 0)?_c('div',{staticClass:"pl-1"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" timer_off ")]),_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(_vm.humanReadableHourDuration(task.waitingDuration))+" ")])],1):_vm._e(),_vm._v(" "+_vm._s(/* Contains check list */)+" "),(task.checkupItems.length > 0)?_c('div',{staticClass:"pl-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" checklist ")])],1):_vm._e()])])]),(hover)?_c('div',{staticClass:"d-flex align-center px-1",style:({
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 1,
                    })},[_c('v-btn',{staticClass:"secondary",attrs:{"icon":"","small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return (function () { return (
                        _vm.editTask({
                          task: task,
                          wpId: task.workPackageId,
                          teamId: task.teamId,
                          x: task.x,
                          y: task.y,
                        })
                      ); }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)],1):_vm._e()])]}}],null,true)})],1)})],2)]],2)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }