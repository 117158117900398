<template>
  <div
    class="full-height"
  >
    <div
      v-hotkey="{ 'esc': reset }"
      style="display: none;"
    />

    <v-tooltip
      absolute
      :position-x="tooltip.x"
      :position-y="tooltip.y"
      :value="tooltip.show"
      top
      content-class="pa-0"
    >
      <v-card
        dark
        class="px-4 py-2"
      >
        {{ tooltip.content }}
      </v-card>
    </v-tooltip>

    <v-menu
      v-if="!!passageMenu.show"
      :value="true"
      :position-x="passageMenu.x"
      :position-y="passageMenu.y"
      absolute
      offset-y
      :close-on-content-click="false"
      :close-on-click="false"
      :transition="false"
      max-height="500px"
      min-width="100px"
    >
      <v-list
        :disabled="$wait.any"
        dense
        class="py-0"
        max-width="400px"
      >
        <v-list-item
          v-for="(passage, i) in passages"
          :key="i"
          :input-value="passageMenu.space.passageId === passage.id"
          @click="() => onPassageChange(passage)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ passage.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-progress-circular
              v-if="$wait.is(`loading ${passageMenu.space.id} ${passage.id}`)"
              :size="12"
              :width="2"
              color="info"
              indeterminate
            />
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-if="passageMenu.space.passageId"
          :three-line="confirmClear"
          style="background-color: rgba(255, 0, 0, 0.1);"
          @click="clearPassage"
        >
          <v-list-item-content>
            <v-list-item-title
              class="error--text"
            >
              <span>
                {{ clearPassageConfirmTitle }}
              </span>
            </v-list-item-title>

            <v-list-item-subtitle
              v-if="confirmClear"
            >
              <span
                v-translate
                translate-context="Page content (project space-time schedule) clear passage tip content"
              >
                If you remove the selection, you will also delete all
                tasks and statistics from this space. You cannot undo this action.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-progress-circular
              v-if="$wait.is('clearing')"
              :size="12"
              :width="2"
              color="info"
              indeterminate
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>

    <m-loader-circular
      v-if="$wait.is('loading data')"
    />

    <m-virtual-table
      v-else
      ref="stickyScroller"
      :items="spaces"
      :item-size="cellBaseWidth"
      :padding-top="120"
      style="max-height: calc(100vh - 96px); height: 100%;"
      :buffer="100"
      class="sticky-scroller"
    >
      <template #before>
        <m-date-header
          :highlight="highlightDay"
          :hover-index="xIndex"
          :project="project"
          :takts="tacts"
          :cell-width="cellWidth"
          :padding-from-start="paddingFromStart"
        >
          <template #corner>
            <th
              style="top: 0;"
              rowspan="3"
              colspan="2"
            >
              <div
                class="d-flex flex-column pa-2"
              >
                <v-btn
                  v-hotkey="spaceEditorKeymap"
                  x-small
                  depressed
                  tile
                  color="secondary darken-4"
                  class="py-4"
                  @click="() => onEditSpace()"
                >
                  <v-icon
                    x-small
                    class="mr-1"
                  >
                    add
                  </v-icon>

                  <span>
                    {{ addSpaceButtonContent }}
                  </span>
                </v-btn>

                <v-tooltip
                  bottom
                  content-class="pa-0"
                >
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      x-small
                      depressed
                      tile
                      color="secondary darken-2"
                      class="py-3"
                      v-on="tooltip"
                      @click="openReOrderingDialog"
                    >
                      <v-icon
                        left
                      >
                        low_priority
                      </v-icon>

                      <span
                        v-translate
                        translate-context="Page content (project space-time schedule) sort spaces button"
                      >
                        sort
                      </span>
                    </v-btn>
                  </template>

                  <v-card
                  >
                    <v-card-text>
                      <span
                        v-translate="{ br: '<br>'}"
                        translate-context="Page content (project space-time schedule) sort spaces tooltip"
                        render-html="true"
                      >
                        Here you can sort spaces
                        %{ br }
                        to another order.
                      </span>
                    </v-card-text>
                  </v-card>
                </v-tooltip>
              </div>
            </th>
          </template>

          <template #titles>
            <th
              v-translate
              translate-context="Page content (project space-time schedule) table header"
              style="top: 96px;"
              class="header-cell-passage"
            >
              Passages
            </th>
            <th
              v-translate
              translate-context="Page content (project space-time schedule) table header"
              style="top: 96px;"
              class="header-cell-takt fixer"
            >
              Takt
            </th>
            <th
              v-translate
              translate-context="Page content (project space-time schedule) table header"
              style="top: 96px;"
              class="header-cell-space fixer"
            >
              Spaces
            </th>
          </template>

          <template #append>
            <tr>
              <th
                colspan="3"
                style="top: 72px;"
              >
                <div
                  class="d-flex justify-space-between px-1"
                >
                  <div
                    v-translate
                    translate-context="Page content (project space-time schedule) table header"
                  >
                    Resource
                  </div>

                  <div>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="secondary darken-4"
                          x-small
                          v-bind="attrs"
                          class="px-1"
                          v-on="on"
                        >
                          <div
                            class="d-flex align-center"
                          >
                            <div
                              style="max-width: 84px"
                              class="text-overflow-ellipsis"
                            >
                              {{ selectedTeamButtonContent }}
                            </div>

                            <v-icon
                              x-small
                            >
                              keyboard_arrow_down
                            </v-icon>
                          </div>
                        </v-btn>
                      </template>

                      <v-list
                        dense
                      >
                        <v-list-item-group
                          v-model="selectedTeam"
                        >
                          <v-list-item
                            v-for="(team, i) in teams"
                            :key="i"
                            :value="team"
                          >
                            <v-list-item-title>{{ team.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </th>
              <th
                v-for="(takt, i) in takts"
                :key="i"
                :colspan="project.tactDuration"
                :style="{
                  top: '72px',
                  position: 'absolute',
                  left: `${paddingFromLeft(takt)}px`,
                  width: `${cellWidth}px`,
                  maxWidth: 'none',
                }"
                :class="{
                  highlight: i === xIndex,
                }"
              >
                {{ workload[takt] ? `${workload[takt] / 60}h` : '—' }}
              </th>
            </tr>
          </template>
        </m-date-header>
      </template>

      <template v-slot="{ item: space, index: y }">
        <th
          class="caption-xxs header-cell-passage"
          :style="{
            position: 'sticky',
            left: 0,
            zIndex: 1,
          }"
        >
          <div
            class="select-passage action-cell full-height text-overflow-ellipsis text-left px-1"
            :class="{
              'backgroundAccent darken-2': space.id === passageMenu.space.id,
              highlight: y === yIndex,
            }"
            :data-space="JSON.stringify(space)"
          >
            {{ space.passageName }}
          </div>
        </th>

        <!--
          having sticky here fixes double outline issue. it doesn't matter
          since it will get hidden under the spaces column anyway.
         -->
        <th
          class="header-cell-takt"
          :style="{
            position: 'sticky',
            left: '64px',
            zIndex: 1,
          }"
        >
          <div
            class="starts-at action-cell full-height relative"
            :class="{
              highlight: y === yIndex,
            }"
          >
            <v-input
              hide-details
              class="input-container"
            >
              <input
                :readonly="$wait.any"
                :value="space.startsOn"
                :data-space-id="space.id"
                min="1"
                step="1"
                type="number"
                class="text-center caption-xxs starts-on-input action-cell"
              >
            </v-input>
          </div>
        </th>

        <th
          :style="{
            position: 'sticky',
            left: '113px',
            zIndex: 1,
          }"
          class="caption-xs header-cell-space"
        >
          <div
            class="edit-space action-cell full-height px-1 white-space-nowrap"
            :class="{
              highlight: y === yIndex,
            }"
            :data-space-id="space.id"
          >
            {{ space.address }}
          </div>
        </th>

        <td
          v-for="(cell, x) in spaceTimeSchedule[space.id]"
          :key="x"
          :style="{
            left: `${paddingFromLeft(cell.takt)}px`,
            width: `${cellWidth}px`,
          }"
        >
          <div
            class="
              cell full-height d-flex justify-center align-center caption-xxs
              font-weight-medium
            "
            :data-wp="JSON.stringify(cell)"
            :data-x-index="cell.takt - 1"
            :data-y-index="y"
          >
            {{ cell.wpOrder + 1 }}
          </div>
        </td>
      </template>
    </m-virtual-table>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MVirtualTable from '@/components/MVirtualTable';
  import MDateHeader from '@/components/virtualTable/MDateHeader';

  export default {
    components: {
      MDateHeader,
      MVirtualTable,
    },

    data: () => ({
      confirmClear: false,
      tooltip: {
        show: false,
        x: null,
        y: null,
        content: '',
      },
      xIndex: null,
      yIndex: null,
      passageMenu: {
        show: false,
        x: null,
        y: null,
        space: {},
      },
      selectedTeam: null,
    }),

    computed: {
      ...mapGetters({
        project: 'project/project/project',
        spaces: 'projectEditor/spaces/spaces',
        spaceTimeSchedule: 'project/project/spaceTimeSchedule',
        tacts: 'project/tacts/tacts',
        passages: 'projectEditor/passages/passages',
        sidePanelVisible: 'sidePanel/isVisible',
        teams: 'project/teams/teams',
        todos: 'project/todos/todos',
      }),

      workload() {
        if (!this.selectedTeam) return {};
        if (this.$wait.is('loading todos')) return {};

        const workload = this.todos.reduce((acc, todo) => {
          if (!acc[todo.takt]) acc[todo.takt] = 0;
          acc[todo.takt] += todo.estimatedDuration;
          return acc;
        }, {});

        return workload;
      },

      takts() {
        return [...new Set(this.tacts.map(takt => takt.taktIndex))];
      },

      spaceEditorShortcut() {
        const lang = window.localStorage.getItem('USER_LOCALE');
        let shortcut = 'S';
        if (lang) {
          // Map shortcut based on lang in use
          switch (lang) {
            case 'fi-FI':
              shortcut = 'M';
              break;

            case 'en-GB':
              shortcut = 'S';
              break;

            default:
              break;
          }
        }

        return shortcut;
      },

      spaceEditorKeymap() {
        const keymap = {};
        keymap[this.spaceEditorShortcut] = this.spaceEditor;
        return keymap;
      },

      addSpaceButtonContent() {
        return `
          ${this.$pgettext('Page content (project space-time schedule) add space button', 'space')} (${this.spaceEditorShortcut})
        `;
      },

      clearPassageConfirmTitle() {
        if (this.confirmClear) {
          return this.$pgettext('Page content (project space-time schedule) clear passage title', 'Are you sure?');
        }

        return this.$pgettext('Page content (project space-time schedule) clear passage title', 'Remove selection');
      },

      selectedTeamButtonContent() {
        if (this.selectedTeam) {
          return this.selectedTeam.name;
        }

        return this.$pgettext('Page content (project space-time schedule) selected team button placeholder', 'Team');
      },

      cellBaseWidth() {
        return 24;
      },

      cellWidth() {
        return this.cellBaseWidth * this.project.tactDuration;
      },

      /**
       * Fixed columns' width on the left.
       *
       * @returns {number}
       */
      paddingFromStart() {
        return 178;
      },
    },

    watch: {
      selectedTeam(team) {
        if (!team) return;
        this.loadWorkload(team.id);
      },
    },

    async created() {
      await Promise.all([
        this.loadSpaces(),
        this.loadSpaceTimeSchedule(),
        this.loadTacts(),
        this.loadPassages(),
        this.loadTeams(),
      ]);

      this.addListeners();
    },

    beforeDestroy() {
      this.destroyListeners();
    },

    methods: {
      ...mapActions({
        openSidePanel: 'sidePanel/openSidePanel',
        addNotification: 'snackbar/addNotification',
        openDialog: 'dialog/openDialog',
      }),

      ...mapWaitingActions('projectEditor/spaces', {
        loadSpaces: 'loading data',
        updateSpace: 'saving space',
      }),

      ...mapWaitingActions('project/project', {
        loadSpaceTimeSchedule: 'loading data',
        reloadSpaceTimeSchedule: {
          action: 'loadSpaceTimeSchedule',
          loader: 'reloading project data',
        },
        updateSpaceTimeSchedule: 'updating schedule',
      }),

      ...mapWaitingActions('project/tacts', {
        loadTacts: 'loading data',
        reloadTacts: {
          action: 'loadTacts',
          loader: 'reloading project data',
        },
      }),

      ...mapWaitingActions('projectEditor/passages', {
        loadPassages: 'loading data',
      }),

      ...mapWaitingActions('project/todos', {
        loadTodos: 'loading todos',
      }),

      ...mapWaitingActions('project/teams', {
        loadTeams: 'loading teams',
      }),

      loadWorkload(teamId) {
        const args = {
          teamIds: [teamId],
          columns: [
            'takt',
            'estimatedDuration',
          ],
        };

        this.loadTodos(args);
      },

      async reloadData(params) {
        if (params) {
          await Promise.all([
            this.updateSpaceTimeSchedule(params),
            this.reloadTacts(),
          ]);
        } else {
          await Promise.all([
            this.reloadSpaceTimeSchedule(),
            this.reloadTacts(),
          ]);
        }
      },

      clearPassage() {
        if (!this.confirmClear) {
          this.confirmClear = true;
          return;
        }

        this.onPassageChange();
      },

      addListeners() {
        this.$refs.stickyScroller.$el.addEventListener('mouseover', this.onCellMouseOver, { passive: true });
        this.$refs.stickyScroller.$el.addEventListener('click', this.onTableClick, { passive: true });
        this.$refs.stickyScroller.$el.addEventListener('change', this.onTableCellChange, { passive: true });
      },

      destroyListeners() {
        this.$refs.stickyScroller.$el.removeEventListener('mouseover', this.onCellMouseOver);
        this.$refs.stickyScroller.$el.removeEventListener('click', this.onTableClick);
        this.$refs.stickyScroller.$el.removeEventListener('change', this.onTableCellChange);
      },

      resetTableState() {
        this.passageMenu.show = false;
        this.passageMenu.x = null;
        this.passageMenu.y = null;
        this.passageMenu.space = {};
        this.confirmClear = false;
      },

      onTableClick(e) {
        this.resetTableState();

        if (e.target.classList.contains('select-passage')) this.onSelectPassage(e);
        if (e.target.classList.contains('edit-space')) this.onEditSpace(e);
      },

      onTableCellChange(e) {
        if (e.target.classList.contains('starts-on-input')) this.updateStartsOn(e);
      },

      spaceEditor() {
        if (this.sidePanelVisible) return;
        this.onEditSpace();
      },

      onEditSpace(e = null) {
        let spaceId = null;
        if (e) spaceId = parseInt(e.target.dataset.spaceId, 10);

        this.openSidePanel({
          component: 'm-space-editor',
          props: {
            spaceId,

            /**
             * Scroll to bottom only when creating a new space
             */
            // onAfterSave: spaceId ? null : this.scrollToBottom,
          },
        });
      },

      onSelectPassage(e) {
        const {
          bottom: y,
          left: x,
        } = e.target.getBoundingClientRect();

        const space = JSON.parse(e.target.dataset.space);
        const {
          id: selectedSpaceId,
        } = this.passageMenu.space;

        if (space.id === selectedSpaceId) {
          this.resetTableState();
        } else {
          this.passageMenu.x = x;
          this.passageMenu.y = y;
          this.passageMenu.space = space;
          this.passageMenu.show = true;
        }
      },

      async onPassageChange(passage = {}) {
        let loaderId;

        if (passage.id) {
          loaderId = `loading ${this.passageMenu.space.id} ${passage.id}`;
        } else {
          loaderId = 'clearing';
        }

        this.$wait.start(loaderId);

        const { id: spaceId } = this.passageMenu.space;
        const params = {
          id: spaceId,
          payload: {
            space: {
              passageId: passage.id || null,
            },
          },
          showNotification: false,
        };

        await this.updateSpace(params);
        await this.reloadData(passage.id ? { spaceId } : null);

        this.$wait.end(loaderId);
        this.resetTableState();
      },

      onCellMouseOver(e) {
        if (e.target.classList.contains('cell')) {
          const {
            top: y,
            left,
            right,
          } = e.target.getBoundingClientRect();

          const x = left + ((right - left) / 2);
          const { dataset } = e.target;
          const wp = JSON.parse(dataset.wp);
          const xIndex = parseInt(dataset.xIndex, 10);
          const yIndex = parseInt(dataset.yIndex, 10);

          /**
           * Creates the tooltip.
           */
          this.tooltip.content = wp.wpName;
          this.tooltip.x = x;
          this.tooltip.y = y;
          this.tooltip.show = true;

          /**
           * Highlights the header cells when hovering data cells.
           */
          this.xIndex = xIndex;
          this.yIndex = yIndex;
        } else if (this.tooltip.show) {
          this.tooltip.show = false;

          this.xIndex = null;
          this.yIndex = null;
        }
      },

      async updateStartsOn(e) {
        const {
          value,
          dataset,
        } = e.target;
        const startsOn = +value;
        const { spaceId } = dataset;

        if (startsOn < 1) {
          // Raise error if trying to start a space at takt 0
          this.addNotification({
            msg: this.$pgettext('MProjectSpaceTimeSchedule smallest takt', 'Takt has to be minimum of 1'),
            type: 'warning',
          });

          return;
        }

        const params = {
          id: spaceId,
          payload: {
            space: {
              startsOn,
            },
          },
          showNotification: false,
        };

        await this.updateSpace(params);
        await this.reloadData({ spaceId });
      },

      highlightDay(i) {
        if (!this.xIndex && this.xIndex !== 0) return false;

        const from = this.xIndex * this.project.tactDuration;
        const to = from + this.project.tactDuration - 1;

        for (let day = from; day <= to; day += 1) {
          const highlight = i === day;
          if (highlight) return true;
        }

        return false;
      },

      openReOrderingDialog() {
        this.openDialog({
          dialogComponent: 'm-reorder-spaces',
          dialogProps: {
            spaces: this.spaces,
          },
          config: {
            fullscreen: this.$vuetify.breakpoint.mobile,
            scrollable: true,
          },
        });

        this.$mixpanel.trackEvent('Space Reordering Dialog', {
          step: 'Open Dialog',
        });
      },

      reset() {
        this.resetTableState();
        document.activeElement.blur(); // Blurs startsOn input field
      },

      paddingFromLeft(takt) {
        const gridPaddingLeft = this.cellBaseWidth * this.project.tactDuration;

        /**
         * First item in the grid should be zero-based. cell.takt
         * starts from 1 so we need to have (cell.takt - 1).
         */
        const cellPosLeft = gridPaddingLeft * (takt - 1);

        return this.paddingFromStart + cellPosLeft;
      },
    },
  };
</script>

<style lang="scss">
.sticky-scroller {
  td, th {
    &.header-cell-passage {
      width: 64px;
      min-width: 64px;
      max-width: 64px;
    }

    &.header-cell-takt {
      width: 48px;
      min-width: 48px;
      max-width: 48px;

      &.fixer {
        width: 49px;
        min-width: 49px;
        max-width: 49px;
      }

      &.sticky {
        position: sticky;
        left: -50px;

        &:hover {
          z-index: 2;
        }
      }
    }

    &.header-cell-space {
      width: 64px;
      min-width: 64px;

      &.fixer {
        width: 65px;
        min-width: 65px;
      }
    }
  }

  .starts-at {
    .input-container {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      input {
        cursor: pointer;
        width: 64px;
        min-height: 24px;
      }
    }
  }
}
</style>
