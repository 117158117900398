var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-height"},[(_vm.$wait.is('loading data'))?_c('m-loader-circular'):_c('m-virtual-table',{ref:"stickyScroller",staticClass:"sticky-scroller",staticStyle:{"max-height":"calc(100vh - 96px)","height":"100%"},attrs:{"items":_vm.teams,"item-size":_vm.cellBaseWidth,"padding-top":96,"buffer":100},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('m-date-header',{attrs:{"highlight":_vm.highlightDay,"hover-index":_vm.xIndex,"project":_vm.project,"takts":_vm.tacts,"cell-width":_vm.cellWidth,"padding-from-start":_vm.paddingFromStart},scopedSlots:_vm._u([{key:"corner",fn:function(){return [_c('th',{staticStyle:{"top":"0"},attrs:{"rowspan":"3"}})]},proxy:true},{key:"titles",fn:function(){return [_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticStyle:{"top":"72px"},attrs:{"translate-context":"Page content (project workload schedule) table header","colspan":"2"}},[_vm._v(" Teams ")])]},proxy:true}])})]},proxy:true},{key:"default",fn:function(ref){
var team = ref.item;
var y = ref.index;
return [_c('th',{staticClass:"caption-xs",style:({
          position: 'sticky',
          left: 0,
          zIndex: 1,
        })},[_c('div',{staticClass:"full-height text-overflow-ellipsis px-1",class:{
            highlight: y === _vm.yIndex,
          }},[_vm._v(" "+_vm._s(team.name)+" ")])]),_vm._l((_vm.workload[team.id]),function(estimatedTime,takt){return _c('td',{key:takt,style:({
          left: (((_vm.cellBaseWidth * (takt - 1) * _vm.project.tactDuration) + _vm.paddingFromStart) + "px"),
          width: ((_vm.cellBaseWidth * _vm.project.tactDuration) + "px"),
        })},[_c('div',{staticClass:"\n            cell full-height d-flex justify-center align-center caption-xxs\n            font-weight-medium\n          ",attrs:{"data-x-index":takt - 1,"data-y-index":y}},[_vm._v(" "+_vm._s(Math.round(estimatedTime / 60))+" h ")])])})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }