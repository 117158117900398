<template>
  <div>
    <h1
      v-translate
      translate-context="Page title"
      class="title text-center my-6"
    >
      Project basics
    </h1>

    <v-form
      ref="form"
      v-model="formValid"
      @submit.prevent="onSubmit"
    >
      <v-card
        max-width="500px"
        class="mx-auto"
      >
        <v-card-text
          class="pa-8"
        >
          <v-alert
            v-if="project.started"
            type="success"
            dense
            text
            class="caption mb-8 py-3"
          >
            <template v-slot:prepend>
              <v-icon
                color="success"
              >
                check_circle
              </v-icon>
            </template>

            <div
              class="d-flex align-center mx-4"
            >
              <div>
                <span v-translate>
                  Some fields cannot be modified since the project has already started.
                </span>

                <template
                  v-if="info"
                >
                  <br><br>
                  <span v-translate>
                    A project is considered started if some of its tasks have been started.
                    If you still need to adjust some of these fields, please call technical support.
                  </span>
                  <br><br>
                  <v-btn
                    href="tel://+358407008296"
                    x-small
                    light
                    color="success"
                    class="px-10 py-3"
                  >
                    <span v-translate>
                      Call
                    </span>
                  </v-btn>
                </template>
              </div>

              <div>
                <v-btn
                  small
                  light
                  text
                  icon
                  color="success"
                  @click="info = !info"
                >
                  <v-icon>
                    {{ info ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-alert>

          <v-text-field
            ref="pid"
            v-model="project.pid"
            :label="projectNamePlaceholder"
            filled
            validate-on-blur
            :rules="[validators.required]"
          />

          <v-text-field
            ref="address"
            v-model="project.address"
            filled
            :label="projectAddressPlaceholder"
            validate-on-blur
            :rules="[validators.required]"
          />

          <v-text-field
            ref="tactDuration"
            v-model="project.tactDuration"
            :disabled="project.started"
            filled
            :label="taktDurationPlaceholder"
            type="number"
            style="max-width: 218px;"
            validate-on-blur
            :rules="[validators.required]"
          />

          <v-menu
            v-model="startsAtMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedStartsAt"
                :disabled="project.started"
                :label="startingDayPlaceholder"
                prepend-inner-icon="event"
                readonly
                filled
                hide-details
                style="max-width: 218px;"
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="project.startsAt"
              color="secondary"
              first-day-of-week="1"
              @input="startsAtMenu = false"
            />
          </v-menu>

          <div
            ref="excludedDates"
            class="mt-8"
          >
            <span
              class="caption text-uppercase primary--text font-weight-bold mb-1 d-block"
            >
              <span v-translate>
                Weekends & holidays
              </span>

              <v-menu
                v-model="excludedDatesMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    icon
                    text
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                    >
                      edit
                    </v-icon>
                  </v-btn>
                </template>

                <v-date-picker
                  v-model="project.excludedDates"
                  color="accent"
                  first-day-of-week="1"
                  scrollable
                  landscape
                  multiple
                />
              </v-menu>
            </span>

            <div
              class="mb-4"
              style="overflow-x: auto; white-space: nowrap;"
            >
              <template
                v-if="project.excludedDates.length > 0"
              >
                <v-chip
                  v-for="(chip, i) in computedExcludedDates"
                  :key="i"
                  close
                  class="mr-1"
                  @click:close="project.excludedDates.splice(i, 1)"
                >
                  {{ chip }}
                </v-chip>
              </template>

              <div
                v-else
                v-translate
                class="font-italic"
              >
                (click on the pen to choose dates)
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions
          class="pb-8 px-8 pt-0"
        >
          <v-btn
            :disabled="$wait.is('saving project')"
            :loading="$wait.is('saving project')"
            block
            type="submit"
            color="success"
            height="64px"
          >
            <span v-translate>
              Save
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import getValidators from '@/mixins/validators';

  export default {
    data: () => ({
      info: false,
      formValid: false,
      project: {
        pid: null,
        address: null,
        startsAt: new Date().toISOString().substr(0, 10),
        excludedDates: [],
        tactDuration: 1,
        started: false,
      },
      startsAtMenu: false,
      excludedDatesMenu: false,
      ...getValidators('required'),
    }),

    computed: {
      ...mapGetters({
        storeProject: 'project/project/project',
      }),

      computedStartsAt() {
        return this.$dayjs(this.project.startsAt).format('L');
      },

      computedExcludedDates() {
        if (!this.project.excludedDates) return [];
        return this.project.excludedDates.map(d => this.$dayjs(d).format('L'));
      },

      projectNamePlaceholder() {
        return this.$pgettext('Text input placeholder', 'Project name');
      },

      projectAddressPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Address');
      },

      taktDurationPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Takt duration (days)');
      },

      startingDayPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Start on');
      },
    },

    mounted() {
      this.toggleWorkloadSidePanel(false);
      this.$refs.pid.focus();

      Object.keys(this.project).forEach((key) => {
        this.project[key] = this.storeProject[key];
      });
    },

    methods: {
      ...mapActions({
        toggleWorkloadSidePanel: 'utils/toggleWorkloadSidePanel',
      }),

      ...mapWaitingActions('project/project', {
        updateProject: 'saving project',
      }),

      async onSubmit() {
        await this.$refs.form.validate();

        if (!this.formValid) return;

        await this.updateProject({
          project: this.project,
        });
      },
    },
  };
</script>
