var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scroller",staticClass:"vue-recycle-scroller",class:{
    ready: _vm.ready,
    'direction-vertical': true,
  },on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[_c('table',{staticClass:"vue-recycle-scroller__item-wrapper",style:({
      minHeight: (_vm.totalSize + "px"),
      width: 'auto',
    })},[(_vm.$slots.before)?_vm._t("before"):_vm._e(),_c('tbody',_vm._l((_vm.pool),function(view){return _c('tr',{key:view.nr.id,staticClass:"vue-recycle-scroller__item-view",style:(_vm.ready ? {
          transform: ("translateY(" + (view.position + _vm.paddingTop) + "px)"),
        } : null)},[_vm._t("default",null,{"item":view.item,"index":view.nr.index,"active":view.nr.used})],2)}),0)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }