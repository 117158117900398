var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"caption-xs d-block"},[_c('tr',[_vm._t("corner"),_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticStyle:{"top":"0"},attrs:{"translate-context":"MDateHeader component abbreviation"}},[_vm._v(" mo ")]),_vm._l((_vm.months),function(month,i){return _c('th',{key:i,staticStyle:{"top":"0"},attrs:{"colspan":month.colspan}},[_vm._v(" "+_vm._s(month.month)+" ")])})],2),_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticStyle:{"top":"24px"},attrs:{"translate-context":"MDateHeader component abbreviation"}},[_vm._v(" wk ")]),_vm._l((_vm.weeks),function(week,i){return _c('th',{key:i,staticStyle:{"top":"24px"},attrs:{"colspan":week.colspan}},[_vm._v(" "+_vm._s(week.week)+" ")])})],2),_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticStyle:{"top":"48px"},attrs:{"translate-context":"MDateHeader component abbreviation"}},[_vm._v(" d ")]),_vm._l((_vm.days),function(day,i){return _c('th',{key:i,class:{
        highlight: _vm.highlight(i),
      },staticStyle:{"top":"48px"}},[_c('div',{class:{
          'sequence-jump': i >= 0 && i < (_vm.days.length - 1) &&
            Number.parseInt(day, 10) + 1 !== Number.parseInt(_vm.days[i + 1], 10)
        }},[_vm._v(" "+_vm._s(day)+" ")])])})],2),_vm._t("append"),_c('tr',[_vm._t("titles"),_vm._l((_vm.taktIndexes),function(takt,i){return _c('th',{key:i,class:{
        highlight: _vm.hoverIndex === i,
      },style:({
        top: _vm.$slots.append ? '96px' : '72px',
        position: 'absolute',
        left: ((_vm.paddingFromLeft(takt)) + "px"),
        width: (_vm.cellWidth + "px"),
        maxWidth: 'none',
      }),attrs:{"colspan":_vm.project.tactDuration}},[_vm._v(" "+_vm._s(takt)+" ")])})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }