<template>
  <div
    class="full-height"
  >
    <m-loader-circular
      v-if="$wait.is('loading data')"
    />

    <m-virtual-table
      v-else
      ref="stickyScroller"
      :items="teams"
      :item-size="cellBaseWidth"
      :padding-top="96"
      style="max-height: calc(100vh - 96px); height: 100%;"
      :buffer="100"
      class="sticky-scroller"
    >
      <template #before>
        <m-date-header
          :highlight="highlightDay"
          :hover-index="xIndex"
          :project="project"
          :takts="tacts"
          :cell-width="cellWidth"
          :padding-from-start="paddingFromStart"
        >
          <template #corner>
            <th
              style="top: 0;"
              rowspan="3"
            />
          </template>

          <template #titles>
            <th
              v-translate
              translate-context="Page content (project workload schedule) table header"
              style="top: 72px;"
              colspan="2"
            >
              Teams
            </th>
          </template>
        </m-date-header>
      </template>

      <template v-slot="{ item: team, index: y }">
        <th
          :style="{
            position: 'sticky',
            left: 0,
            zIndex: 1,
          }"
          class="caption-xs"
        >
          <div
            class="full-height text-overflow-ellipsis px-1"
            :class="{
              highlight: y === yIndex,
            }"
          >
            {{ team.name }}
          </div>
        </th>

        <td
          v-for="(estimatedTime, takt) in workload[team.id]"
          :key="takt"
          :style="{
            left: `${(cellBaseWidth * (takt - 1) * project.tactDuration) + paddingFromStart}px`,
            width: `${cellBaseWidth * project.tactDuration}px`,
          }"
        >
          <div
            class="
              cell full-height d-flex justify-center align-center caption-xxs
              font-weight-medium
            "
            :data-x-index="takt - 1"
            :data-y-index="y"
          >
            {{ Math.round(estimatedTime / 60) }} h
          </div>
        </td>
      </template>
    </m-virtual-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MVirtualTable from '@/components/MVirtualTable';
  import MDateHeader from '@/components/virtualTable/MDateHeader';

  export default {
    components: {
      MDateHeader,
      MVirtualTable,
    },

    data: () => ({
      xIndex: null,
      yIndex: null,
    }),

    computed: {
      ...mapGetters({
        project: 'project/project/project',
        tacts: 'project/tacts/tacts',
        teams: 'project/teams/teams',
        todos: 'project/todos/todos',
      }),

      workload() {
        if (this.$wait.is('loading data')) return {};

        const workload = this.todos.reduce((acc, todo) => {
          if (!acc[todo.teamId]) acc[todo.teamId] = {};
          if (!acc[todo.teamId][todo.takt]) acc[todo.teamId][todo.takt] = 0;
          acc[todo.teamId][todo.takt] += todo.estimatedDuration;
          return acc;
        }, {});

        return workload;
      },

      cellBaseWidth() {
        return 24;
      },

      cellWidth() {
        return this.cellBaseWidth * this.project.tactDuration;
      },

      /**
       * Fixed columns' width on the left.
       *
       * @returns {number}
       */
      paddingFromStart() {
        return 128;
      },
    },

    async created() {
      await Promise.all([
        this.loadTacts(),
        this.loadTeams(),
        this.loadTodos({
          columns: [
            'takt',
            'estimatedDuration',
            'teamId',
          ],
        }),
      ]);

      this.addListeners();
    },

    beforeDestroy() {
      this.destroyListeners();
    },

    methods: {
      ...mapWaitingActions('project/tacts', {
        loadTacts: 'loading data',
      }),

      ...mapWaitingActions('project/todos', {
        loadTodos: 'loading data',
      }),

      ...mapWaitingActions('project/teams', {
        loadTeams: 'loading data',
      }),

      addListeners() {
        this.$refs.stickyScroller.$el.addEventListener('mouseover', this.onCellMouseOver, { passive: true });
      },

      destroyListeners() {
        this.$refs.stickyScroller.$el.removeEventListener('mouseover', this.onCellMouseOver);
      },

      onCellMouseOver(e) {
        if (e.target.classList.contains('cell')) {
          const { dataset } = e.target;
          const xIndex = parseInt(dataset.xIndex, 10);
          const yIndex = parseInt(dataset.yIndex, 10);

          /**
           * Highlights the header cells when hovering data cells.
           */
          this.xIndex = xIndex;
          this.yIndex = yIndex;
        } else {
          this.xIndex = null;
          this.yIndex = null;
        }
      },

      highlightDay(i) {
        if (!this.xIndex && this.xIndex !== 0) return false;

        const from = this.xIndex * this.project.tactDuration;
        const to = from + this.project.tactDuration - 1;

        for (let day = from; day <= to; day += 1) {
          const highlight = i === day;
          if (highlight) return true;
        }

        return false;
      },
    },
  };
</script>

<style lang="scss">
.sticky-scroller {
  // Sticky column title
  thead tr:first-of-type th:first-of-type {
    width: 104px;
    min-width: 104px;
    max-width: 104px;
  }

  // Sticky column cells
  tbody th {
    width: 127px;
    min-width: 127px;
    max-width: 127px;
  }
}
</style>
