<template>
  <div>
    <v-app-bar
      dense
      elevation="0"
    >
      <div
        v-hotkey="{ 'L': passageEditor }"
        style="display: none;"
      />

      <div
        v-hotkey="{ 'T': teamEditor }"
        style="display: none;"
      />

      <div
        v-hotkey="{ 'P': wpEditor }"
        style="display: none;"
      />

      <v-select
        v-model="selectedPassageId"
        :items="passages"
        :label="selectPassagePlaceholder"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
        style="max-width: 300px;"
        menu-props="offsetY, closeOnContentClick"
      >
        <template #prepend-item>
          <v-list-item
            @click="() => editPassage()"
          >
            <v-list-item-avatar>
              <v-icon>
                add
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-translate>
                Create passage
              </v-list-item-title>

              <v-list-item-subtitle
                v-translate
                translate-context="Keyboard shortcut"
              >
                Shortcut L
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
        </template>

        <template #item="{ item: passage }">
          <v-list-item
            :input-value="selectedPassageId === passage.id"
            @click="selectedPassageId = passage.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ passage.name }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                small
                @click.stop="() => editPassage(passage.id)"
              >
                <v-icon
                  small
                >
                  edit
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-select>

      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              :disabled="!selectedPassageId"
              small
              outlined
              class="mx-4"
              v-on="on"
              @click="() => editTeam()"
            >
              <span v-translate>
                Create team
              </span>
            </v-btn>
          </span>
        </template>

        <span>
          {{ createTeamTooltip }}
        </span>
      </v-tooltip>

      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              :disabled="!selectedPassageId || teams.length <= 0"
              small
              outlined
              v-on="on"
              @click="() => editWorkPackage()"
            >
              <span v-translate>
                Create work package
              </span>
            </v-btn>
          </span>
        </template>

        <span>
          <template
            v-if="!selectedPassageId"
            v-translate
          >
            Select a passage first
          </template>

          <template
            v-else-if="teams.length <= 0"
            v-translate
          >
            Create teams first
          </template>

          <template
            v-else
            v-translate
            translate-context="Keyboard shortcut"
          >
            Shortcut P
          </template>
        </span>
      </v-tooltip>

      <v-spacer />

      <v-menu
        v-model="zoomDropDown"
        offset-y
        :close-on-content-click="false"
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              zoom_in
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <v-subheader
              v-translate
              translate-context="Table zoom control"
            >
              Horizontal
            </v-subheader>

            <v-btn
              :disabled="CARD_WIDTH <= MIN_CARD_WIDTH"
              icon
              @click="() => changeCardSize({ dx: -1 })"
            >
              <v-icon
                size="12"
              >
                remove
              </v-icon>
            </v-btn>

            <v-btn
              icon
              @click="() => changeCardSize({ dx: 1 })"
            >
              <v-icon
                size="12"
              >
                add
              </v-icon>
            </v-btn>

            <v-subheader
              v-translate
              translate-context="Table zoom control"
              class="mt-12"
            >
              Vertical
            </v-subheader>

            <v-btn
              :disabled="CARD_HEIGHT <= MIN_CARD_HEIGHT"
              icon
              @click="() => changeCardSize({ dy: -1 })"
            >
              <v-icon
                size="12"
              >
                remove
              </v-icon>
            </v-btn>

            <v-btn
              icon
              @click="() => changeCardSize({ dy: 1 })"
            >
              <v-icon
                size="12"
              >
                add
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <div
      class="d-flex"
      style="
        height: calc(100vh - 144px);
        overflow: auto;
      "
    >
      <m-tip-note
        v-if="!selectedPassageId"
        :title="noChosenPassageTipTitle"
        :tip="noChosenPassageTip"
      />

      <template
        v-else
      >
        <div
          style="position: sticky; left: 0; z-index: 2;"
        >
          <!-- upper left corner -->
          <div
            :style="{
              height: `${CARD_HEIGHT}px`,
            }"
          >
            <v-tooltip
              bottom
              content-class="pa-0"
            >
              <template #activator="{ on: tooltip }">
                <v-btn
                  text
                  small
                  block
                  tile
                  height="100%"
                  v-on="tooltip"
                  @click="openReOrderingDialog"
                >
                  <v-icon
                    left
                  >
                    low_priority
                  </v-icon>

                  <span
                    v-translate
                    translate-context="Passage table"
                  >
                    sort
                  </span>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <span
                    v-translate
                    translate-context="Sort packages tooltip [1/2]"
                  >
                    Here you can sort work
                  </span>
                  <br>
                  <span
                    v-translate
                    translate-context="Sort packages tooltip [2/2]"
                  >
                    packages to another order.
                  </span>
                </v-card-text>
              </v-card>
            </v-tooltip>
          </div>

          <div
            class="d-flex flex-column align-end"
          >
            <v-hover
              v-for="(wp, i) in workPackages"
              :key="i"
              v-slot="{ hover }"
            >
              <v-card
                class="wp-card d-flex flex-column resizable relative"
                :width="MIN_CARD_WIDTH"
                :ripple="false"
                tile
                color="backgroundAccent lighten-1"
                elevation="6"
                :data-wp-id="wp.id"
                :data-row-count="wp.rowCount"
              >
                <!-- horizontal grid line -->
                <div
                  :style="{
                    position: 'absolute',
                    bottom: 0,
                    left: '100%',
                    width: `${(teams.length + 1) * CARD_WIDTH}px`,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                    pointerEvents: 'none',
                  }"
                />

                <!-- edit btn -->
                <div
                  v-if="hover"
                  class="d-flex align-center px-1"
                  :style="{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                  }"
                >
                  <v-btn
                    icon
                    small
                    dark
                    class="secondary"
                    @click="() => editWorkPackage(wp.id)"
                  >
                    <v-icon
                      small
                    >
                      edit
                    </v-icon>
                  </v-btn>
                </div>

                <!-- wp name -->
                <div
                  class="d-flex justify-center align-center full-height caption font-weight-bold"
                >
                  {{ wp.name }}
                </div>

                <!-- resize handlebar -->
                <div
                  class="resize-bottom full-width"
                  :style="{
                    position: 'absolute',
                    height: '12px',
                    right: 0,
                    bottom: '-6px',
                    left: 0,
                    zIndex: 1,
                  }"
                />
              </v-card>
            </v-hover>
          </div>
        </div>

        <!--
          For some reason height and therefore the sticky scroll
          was not set correctly, hence using display: table;
        -->
        <div
          class="full-width"
          style="display: table;"
        >
          <m-tip-note
            v-if="teams.length <= 0"
            :title="noTeamsTipTitle"
            :tip="noTeamsTip"
          />

          <template
            v-else
          >
            <div
              class="white-space-nowrap"
              style="position: sticky; top: 0; z-index: 2;"
              :style="{
                height: `${CARD_HEIGHT}px`,
              }"
            >
              <!-- No team -->
              <div
                :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  transform: `translate(${0 * CARD_WIDTH}px)`,
                }"
                class="team-card draggable"
                :data-order=0
                :data-coord-x=0
                :data-pos-x="0 * CARD_WIDTH"
              >
                <v-card
                  class="overflow-hidden"
                  :width="CARD_WIDTH"
                  :height="CARD_HEIGHT"
                  :ripple="false"
                  tile
                  color="backgroundAccent"
                  elevation="3"
                  :data-column-index="i"
                >
                  <div
                    class="d-flex justify-center align-center overflow-hidden
                    caption-xs font-weight-bold full-height text-center drag-handle"
                  >
                    NO TEAM
                  </div>
                </v-card>
                <div
                  :style="{
                    position: 'absolute',
                    top: '100%',
                    left: '100%',
                    marginLeft: '-1px',
                    height: `${dropzoneHeight}px`,
                    borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
                    pointerEvents: 'none',
                  }"
                />
              </div>
              <!-- For each team -->
              <div
                v-for="(team, i) in teams"
                :ref="`team-${team.id}`"
                :key="i"
                :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  transform: `translate(${(team.order + 1) * CARD_WIDTH}px)`,
                }"
                class="team-card draggable"
                :data-order="team.order + 1"
                :data-coord-x="(team.order + 1)"
                :data-pos-x="(team.order + 1) * CARD_WIDTH"
                :data-team-id="team.id"
              >
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-card
                    class="overflow-hidden"
                    :width="CARD_WIDTH"
                    :height="CARD_HEIGHT"
                    :ripple="false"
                    tile
                    color="backgroundAccent lighten-1"
                    elevation="3"
                    :data-column-index="i"
                  >
                    <div
                      v-if="hover"
                      class="d-flex align-center px-1"
                      :style="{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                      }"
                    >
                      <v-btn
                        icon
                        small
                        dark
                        class="secondary"
                        @click.stop="() => editTeam(team.id)"
                      >
                        <v-icon
                          small
                        >
                          edit
                        </v-icon>
                      </v-btn>
                    </div>

                    <div
                      class="d-flex justify-center align-center overflow-hidden
                      caption-xs font-weight-bold full-height text-center drag-handle"
                    >
                      {{ team.name }}
                    </div>
                  </v-card>
                </v-hover>

                <div
                  :style="{
                    position: 'absolute',
                    top: '100%',
                    left: '100%',
                    marginLeft: '-1px',
                    height: `${dropzoneHeight}px`,
                    borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
                    pointerEvents: 'none',
                  }"
                />
              </div>
            </div>

            <m-tip-note
              v-if="workPackages.length <= 0"
              :title="noWorkPackagesTipTitle"
              :tip="noWorkPackagesTip"
            />

            <div
              v-else
              class="dropzone relative"
              :style="{
                width: `${CARD_WIDTH * (teams.length + 1)}px`,
                height: `${dropzoneHeight}px`,
                marginRight: '1px',
                marginBottom: '1px',
              }"
            >
              <div
                :style="{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }"
                class="d-flex justify-center align-center caption font-italic backgroundAccent--text"
                @click="onDropzoneClick"
              >
                <span
                  v-if="tasks.length <= 0"
                  v-translate
                  translate-context="Passage table"
                  class="text-center"
                  style="pointer-events: none;"
                >
                  Click on a cell to create a task
                </span>
              </div>

              <v-card
                v-for="(task, i) in tasks"
                :key="i"
                :width="CARD_WIDTH"
                :height="CARD_HEIGHT"
                :ripple="false"
                tile
                :dark="task.hex !== '#FFFFFF'"
                :data-order="task.order"
                :data-coord-x="task.x == null ? 0 : task.x + 1"
                :data-coord-y="task.y"
                :data-task-id="task.id"
                :data-wp-id="task.workPackageId"
                :color="task.hex"
                class="task-card draggable"
                style="position: absolute;"
                @click=""
              >
                <v-tooltip
                  v-if="task.requiresSupervision"
                  top
                >
                  <template #activator="{ on: tooltip }">
                    <v-icon
                      color="accent"
                      :style="{
                        position: 'absolute',
                        bottom: '-6px',
                        right: '-6px',
                        zIndex: 1,
                      }"
                      v-on="tooltip"
                    >
                      pan_tool
                    </v-icon>
                  </template>

                  <span>
                    <span
                      v-translate
                      translate-context="Pace-setting task tooltip [1/3]"
                    >
                      Pace-setting task.
                    </span>
                    <br><br>
                    <span
                      v-translate
                      translate-context="Pace-setting task tooltip [2/3]"
                    >
                      This task has to be completed
                    </span>
                    <br>
                    <span
                      v-translate
                      translate-context="Pace-setting task tooltip [3/3]"
                    >
                      before following tasks can be started.
                    </span>
                  </span>
                </v-tooltip>

                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    class="d-flex full-height relative"
                  >
                    <div
                      class="flex-grow-1 drag-handle caption-xxs d-flex align-top"
                      :style="{
                        padding: '2px',
                        overflow: 'hidden',
                      }"
                      @click.stop
                    >
                    <div class="d-flex flex-column pl-1 pt-1">
                      {{/*  card content  */}}
                      <div class="task-name" :style="{
                          overflowY: 'hidden',
                      }">
                        {{ task.name }} 
                      </div>
                      <div class="task-attributes flex-grow-1 d-flex flex-row">
                        {{/*  Estimated duration  */}}
                        <div>
                          <v-icon
                            x-small>
                            timer
                          </v-icon>
                          <span v-if="task.estimatedDuration > 0">
                            {{ humanReadableHourDuration(task.estimatedDuration) }}
                          </span>
                          <span v-else>
                            --
                          </span> 
                        </div>
                        {{/*  Waiting time  */}}
                        <div v-if="task.waitingDuration > 0" class="pl-1">
                          <v-icon
                            x-small>
                            timer_off
                          </v-icon>
                          <span style="vertical-align:middle;">
                          {{ humanReadableHourDuration(task.waitingDuration) }}
                          </span>
                        </div>
                        {{/*  Contains check list  */}}
                        <div v-if="task.checkupItems.length > 0" class="pl-1">
                          <v-icon
                            small>
                            checklist
                          </v-icon>
                        </div>
                      </div>
                    </div>

                    </div>

                    <div
                      v-if="hover"
                      class="d-flex align-center px-1"
                      :style="{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                      }"
                    >
                      <v-btn
                        icon
                        small
                        dark
                        class="secondary"
                        @click.stop="() => (
                          editTask({
                            task,
                            wpId: task.workPackageId,
                            teamId: task.teamId,
                            x: task.x,
                            y: task.y,
                          })
                        )"
                      >
                        <v-icon
                          small
                        >
                          edit
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-hover>
              </v-card>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs'; // eslint-disable-line
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import {
    cacheGridCardSize,
    readCachedGridCardSize,
    cacheSelectedPassageId,
    getCachedPassageIds,
  } from '@/vuex/utils/localStorage';

  export default {
    data: () => ({
      CARD_WIDTH: 110,
      CARD_HEIGHT: 40,

      MIN_CARD_WIDTH: 110,
      MIN_CARD_HEIGHT: 40,

      selectedPassageId: null,
      zoomDropDown: false,
    }),

    computed: {
      ...mapGetters({
        __workPackages__: 'projectEditor/workPackages/workPackages',
        teams: 'projectEditor/teams/teams',
        tasks: 'projectEditor/tasks/tasks',
        passages: 'projectEditor/passages/passages',
        sidePanelVisible: 'sidePanel/isVisible',
      }),

      /**
       * This view needs the wps array sorted.
       */
      workPackages() {
        return this
          .__workPackages__
          .slice()
          .sort((a, b) => (a.order > b.order) ? 1 : -1); // eslint-disable-line
      },

      dropzoneHeight() {
        const multipliers = this.workPackages.reduce((acc, wp) => acc + (wp.rowCount || 1), 0);

        return this.CARD_HEIGHT * multipliers;
      },

      selectPassagePlaceholder() {
        if (this.selectedPassageId) {
          return '';
        }

        return this.$pgettext('Select component placeholder', 'Select a passage');
      },

      createTeamTooltip() {
        if (this.selectedPassageId) {
          return this.$pgettext('Keyboard shortcut', 'Shortcut T');
        }

        return this.$gettext('Select a passage first');
      },

      noChosenPassageTipTitle() {
        return this.$pgettext('Tip note title', 'No passage chosen.');
      },

      noChosenPassageTip() {
        return this.$pgettext('Tip note', 'Select a passage from the top menu.');
      },

      noTeamsTipTitle() {
        return this.$pgettext('Tip note title', 'No teams.');
      },

      noTeamsTip() {
        return this.$pgettext('Tip note', 'Create a team from the top menu.');
      },

      noWorkPackagesTipTitle() {
        return this.$pgettext('Tip note title', 'No work packages.');
      },

      noWorkPackagesTip() {
        return this.$pgettext('Tip note', 'Create a work package from the top menu.');
      },
    },

    watch: {
      tasks() {
        this.$nextTick(() => {
          this.recalculateTaskCards();
        });
      },

      workPackages() {
        this.$nextTick(() => {
          this.recalculateTaskCards();
          this.recalculateWorkPackageCards();
        });
      },

      selectedPassageId(passageId) {
        cacheSelectedPassageId({
          [this.$projectId]: {
            selectedPassageId: passageId,
          },
        });

        if (!passageId) return;

        this.loadWorkPackages({ passageId });
        this.loadTasksForPassage();
      },
    },

    async mounted() {
      this.setCardSize();

      await Promise.all([
        this.loadTeams(),
        this.loadPassages(),
      ]);

      this.initTaskInteractions();
      this.initWpInteractions();
      this.initSelectedPassageId();
    },

    methods: {
      ...mapActions({
        loadWorkPackages: 'projectEditor/workPackages/loadWorkPackages',
        loadTeams: 'projectEditor/teams/loadTeams',
        loadTasks: 'projectEditor/tasks/loadTasks',
        loadPassages: 'projectEditor/passages/loadPassages',
        openSidePanel: 'sidePanel/openSidePanel',
        addNotification: 'snackbar/addNotification',
        openDialog: 'dialog/openDialog',
      }),

      ...mapWaitingActions('projectEditor/workPackages', {
        updateWorkPackage: 'batch saving workPackages',
      }),

      ...mapWaitingActions('projectEditor/tasks', {
        updateTask: 'saving task',
        batchUpdateTasks: 'batch saving tasks',
      }),

      ...mapWaitingActions('projectEditor/teams', {
        batchUpdateTeams: 'batch saving teams',
      }),

      initSelectedPassageId() {
        const cachedData = getCachedPassageIds()[this.$projectId];
        if (!cachedData) return;
        this.selectedPassageId = cachedData.selectedPassageId;
      },

      initTaskInteractions() {
        const targetClass = '.task-card';

        interact(targetClass).unset();

        interact(targetClass).draggable({
          allowFrom: '.drag-handle',
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: this.CARD_WIDTH, y: this.CARD_HEIGHT }),
              ],
              range: Infinity,
              offset: 'startCoords',
            }),
            interact.modifiers.restrict({
              restriction: 'parent',
              elementRect: {
                top: 0, left: 0, bottom: 1, right: 1,
              },
            }),
          ],
          listeners: {
            start: (event) => {
              const {
                coordX,
                coordY,
                posX,
                posY,
              } = event.target.dataset;

              event.target.setAttribute('data-prev-coord-x', coordX);
              event.target.setAttribute('data-prev-coord-y', coordY);

              event.target.setAttribute('data-prev-pos-x', posX);
              event.target.setAttribute('data-prev-pos-y', posY);
            },
            move: (event) => {
              const {
                dx,
                dy,
              } = event;

              this.recalculateTaskCards({ dx, dy, el: event.target });
            },
            end: (event) => {
              const {
                target,
              } = event;

              const coordX = parseFloat(target.dataset.coordX);
              const coordY = parseFloat(target.dataset.coordY);
              const posY = parseFloat(target.dataset.posY);

              const occupied = this.tasks.some(t => t.x + 1 === coordX && t.y === coordY);

              if (occupied) {
                const {
                  prevPosX,
                  prevPosY,
                  prevCoordX,
                  prevCoordY,
                } = target.dataset;

                // translate the element
                target.style.webkitTransform
                = target.style.transform // eslint-disable-line
                = `translate(${prevPosX}px, ${prevPosY}px)`; // eslint-disable-line

                // update the position attributes
                target.setAttribute('data-pos-x', prevPosX);
                target.setAttribute('data-pos-y', prevPosY);

                target.setAttribute('data-coord-x', prevCoordX);
                target.setAttribute('data-coord-y', prevCoordY);

                return;
              }

              const wp = this.workPackages[this.findWpIndex(coordY)];
              const team = this.teams[coordX - 1];
              const {
                taskId,
              } = target.dataset;

              const prevRowCount = this.rowCountUntil(coordY);
              const fromTop = this.CARD_HEIGHT * prevRowCount;
              const row = (posY - fromTop) / this.CARD_HEIGHT;

              this.taskUpdate(taskId, {
                workPackageId: wp.id,
                teamId: coordX === 0 ? null : team.id,
                x: coordX,
                y: coordY,
                order: row,
              });
            },
          },
        });
      },

      initWpInteractions() {
        const targetClass = '.wp-card';

        interact(targetClass).unset();

        interact(targetClass)
          .resizable({
            edges: {
              bottom: '.resize-bottom',
            },
            modifiers: [
              interact.modifiers.snapSize({
                targets: [
                  interact.createSnapGrid({ width: this.CARD_WIDTH, height: this.CARD_HEIGHT }),
                ],
              }),
              interact.modifiers.restrictSize({
                min: { height: this.CARD_HEIGHT },
              }),
            ],
          })
          .on('resizestart', (event) => {
            const {
              rowCount,
            } = event.target.dataset;

            event.target.setAttribute('data-prev-row-count', rowCount);
          })
          .on('resizemove', (event) => {
            const {
              wpId,
            } = event.target.dataset;

            const {
              height: targetHeight,
            } = event.rect;

            const targetRowCount = targetHeight / this.CARD_HEIGHT;
            const minRowCount = this
              .tasks
              .filter(t => t.workPackageId.toString() === wpId)
              .reduce((acc, task) => Math.max(acc, task.order), 0) + 1; // Zero-based, hence + 1

            if (targetRowCount < minRowCount) return;

            // Updates the wp element height
            Object.assign(event.target.style, {
              height: `${targetHeight}px`,
            });

            // Saves the row count
            event.target.setAttribute('data-row-count', targetRowCount);
          })
          .on('resizeend', (event) => {
            const {
              wpId,
              rowCount: newRowCount,
              prevRowCount,
            } = event.target.dataset;

            const minRowCount = this
              .tasks
              .filter(t => t.workPackageId.toString() === wpId)
              .reduce((acc, task) => Math.max(acc, task.order), 0) + 1; // Zero-based, hence + 1

            if (newRowCount < minRowCount) return;

            this.updateWp(wpId, { rowCount: newRowCount });

            this.updateAffectedTasks({
              changedWpId: wpId,
              rowDifference: newRowCount - prevRowCount,
            });
          });
      },

      editTeam(teamId = null) {
        this.openSidePanel({
          component: 'm-team-editor',
          props: {
            teamId,
            onDestroy: async () => {
              /**
               * Destroying a team destroys its associated
               * tasks, hence we need to reload tasks.
               *
               * Also, teams' orders may have changed so
               * reload those too.
               */
              await Promise.all([
                this.loadTasksForPassage(),
                this.loadTeams(),
              ]);
            },
          },
        });
      },

      loadTasksForPassage() {
        return this.loadTasks({ passageId: this.selectedPassageId });
      },

      editWorkPackage(workPackageId = null) {
        this.openSidePanel({
          component: 'm-work-package-editor',
          props: {
            workPackageId,
            passageId: this.selectedPassageId,
            onBeforeDestroy: async () => {
              const wp = this.workPackages.find(w => w.id === workPackageId);

              await this.updateAffectedTasks({
                changedWpId: workPackageId,
                rowDifference: -wp.rowCount,
              });
            },
            onDestroy: () => {
              /**
               * Reload workPackages on destroy since the orders may have changed
               */
              this.loadWorkPackages({ passageId: this.selectedPassageId });
              this.loadTasksForPassage();
            },
          },
        });
      },

      onDropzoneClick(event) {
        const {
          offsetX,
          offsetY,
        } = event;

        const x = Math.floor((offsetX / this.CARD_WIDTH));
        const y = Math.floor((offsetY / this.CARD_HEIGHT));
        const prevRowCount = this.rowCountUntil(y);
        const fromTop = this.CARD_HEIGHT * prevRowCount;
        const order = Math.floor((offsetY - fromTop) / this.CARD_HEIGHT);

        this.editTask({
          wpId: this.workPackages[this.findWpIndex(y)].id,
          teamId: this.teams[x - 1].id,
          order,
          x,
          y,
        });
      },

      /**
       * Since workPackages can be of any height, we have
       * to find the correct workPackage manually.
       *
       * @returns {number}
       */
      findWpIndex(y) {
        let row = y;
        let wpIndex = -1;

        while (row >= 0) {
          row -= this.workPackages[wpIndex + 1].rowCount || 1;
          wpIndex += 1;
        }

        return wpIndex;
      },

      rowCountUntil(y) {
        const until = this.findWpIndex(y);
        return this.workPackages.slice(0, until).reduce((acc, wp) => acc + wp.rowCount, 0);
      },

      editPassage(passageId = null) {
        this.openSidePanel({
          component: 'm-passage-editor',
          props: {
            passageId,
            onBeforeDestroy: () => {
              if (this.selectedPassageId === passageId) {
                this.selectedPassageId = null;
              }
            },
            onSave: (passage) => {
              this.selectedPassageId = passage.id;
            },
          },
        });
      },

      editTask({
        task = {},
        wpId: workPackageId,
        teamId,
        order,
        x,
        y,
      }) {
        this.openSidePanel({
          component: 'm-task-editor',
          props: {
            taskId: task.id || null,
            workPackageId,
            teamId,
            order: task.order || order,
            x,
            y,
          },
        });
      },

      recalculateTaskCards({ dx = 0, dy = 0, el = null } = { dx: 0, dy: 0, el: null }) {
        const calculateForElement = (target) => {
          const {
            coordX: x,
            coordY: y,
          } = target.dataset;

          const left = (this.CARD_WIDTH * x) + dx;
          const top = (this.CARD_HEIGHT * y) + dy;

          const coordX = Math.round(left / this.CARD_WIDTH);
          const coordY = Math.round(top / this.CARD_HEIGHT);

        target.style.webkitTransform = target.style.transform = `translate(${left}px, ${top}px)`; // eslint-disable-line

          target.setAttribute('data-pos-x', left);
          target.setAttribute('data-pos-y', top);

          target.setAttribute('data-coord-x', coordX);
          target.setAttribute('data-coord-y', coordY);
        };

        if (el) {
          calculateForElement(el);
        } else {
          document.querySelectorAll('.task-card').forEach(calculateForElement);
        }
      },

      recalculateWorkPackageCards() {
        document.querySelectorAll('.wp-card').forEach((el) => {
          const {
            rowCount,
          } = el.dataset;

          Object.assign(el.style, {
            height: `${this.CARD_HEIGHT * rowCount}px`,
          });
        });
      },

      async updateWp(wpId, payload) {
        const params = {
          id: wpId,
          payload: {
            workPackage: payload,
          },
          notify: false,
        };

        await this.updateWorkPackage(params);
      },

      taskUpdate(taskId, payload) {
        const params = {
          id: taskId,
          payload: {
            task: payload,
          },
        };

        return this.updateTask(params);
      },

      passageEditor() {
        if (this.sidePanelVisible) return;
        this.editPassage();
      },

      teamEditor() {
        if (this.sidePanelVisible) return;
        this.editTeam();
      },

      wpEditor() {
        if (this.sidePanelVisible) return;
        this.editWorkPackage();
      },

      updateAffectedTasks({
        changedWpId = null,
        rowDifference = 0,
      }) {
        let affectedTasks = this.tasks;

        if (changedWpId) {
          const wpIndex = this.workPackages
            .findIndex(wp => wp.id.toString() === changedWpId.toString());
          const wpIds = this
            .workPackages
            .slice(wpIndex + 1)
            .map(wp => wp.id);

          affectedTasks = affectedTasks.filter(t => wpIds.includes(t.workPackageId));
        }

        const payload = affectedTasks
          .map(t => ({
            id: t.id,
            y: t.y + rowDifference,
          }));

        if (payload.length <= 0) return;

        this.batchUpdateTasks({
          tasks: payload,
        });
      },

      changeCardSize({ dx = 0, dy = 0 }) {
        const multiplier = 8;

        const cardWidth = this.CARD_WIDTH + (dx * multiplier);
        const cardHeight = this.CARD_HEIGHT + (dy * multiplier);

        this.CARD_WIDTH = Math.max(cardWidth, this.MIN_CARD_WIDTH);
        this.CARD_HEIGHT = Math.max(cardHeight, this.MIN_CARD_HEIGHT);

        cacheGridCardSize({ w: this.CARD_WIDTH, h: this.CARD_HEIGHT });

        this.recalculateTaskCards();
        this.recalculateWorkPackageCards();

        this.initTaskInteractions();
        this.initWpInteractions();
      },

      setCardSize() {
        const {
          w = this.MIN_CARD_WIDTH,
          h = this.MIN_CARD_HEIGHT,
        } = readCachedGridCardSize();

        // Restore if cached sizes are larger or equal than defined minimums.
        // otherwise fallback to mins
        if (w >= this.MIN_CARD_WIDTH){
          this.CARD_WIDTH = w;
        } else {
          this.CARD_WIDTH = this.MIN_CARD_WIDTH
        }
        if (h >= this.MIN_CARD_HEIGHT){
          this.CARD_HEIGHT = h;
        } else {
          this.CARD_HEIGHT = this.MIN_CARD_HEIGHT
        }
      },

      humanReadableHourDuration(durationMinutes) {
        const d = Math.round((durationMinutes / 60) * 10) / 10;

        return `${d}h`;
      },

      openReOrderingDialog() {
        this.openDialog({
          dialogComponent: 'm-reorder-work-packages-and-teams',
          dialogProps: {
            workPackages: this.workPackages,
            teams: this.teams,
            onAfterTeamsSave: this.loadTasksForPassage,
          },
          config: {
            fullscreen: this.$vuetify.breakpoint.mobile,
            scrollable: true,
          },
        });

        this.$mixpanel.trackEvent('Workpackage Reordering Dialog', {
          step: 'Open Dialog',
        });
      },
    },
  };
</script>
<style lang="scss">
.task-name {
  font-size: small;
  font-weight: bold;
  line-height: 13px;
}

.task-attributes {
  font-size: small;
}
</style>
