<template>
  <div
    ref="scroller"
    class="vue-recycle-scroller"
    :class="{
      ready,
      'direction-vertical': true,
    }"
    @scroll.passive="handleScroll"
  >
    <table
      :style="{
        minHeight: `${totalSize}px`,
        width: 'auto',
      }"
      class="vue-recycle-scroller__item-wrapper"
    >
      <slot
        v-if="$slots.before"
        name="before"
      />

      <tbody>
        <tr
          v-for="view of pool"
          :key="view.nr.id"
          :style="ready ? {
            transform: `translateY(${view.position + paddingTop}px)`,
          } : null"
          class="vue-recycle-scroller__item-view"
        >
          <slot
            :item="view.item"
            :index="view.nr.index"
            :active="view.nr.used"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import config from 'vue-virtual-scroller/src/config';
  import { props, simpleArray } from 'vue-virtual-scroller/src/components/common';

  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

  let uid = 0;

  export default {
    name: 'MVirtualTable',

    props: {
      ...props,

      paddingTop: {
        type: Number,
        default: 0,
      },

      itemSize: {
        type: Number,
        default: null,
      },

      minItemSize: {
        type: [Number, String],
        default: null,
      },

      sizeField: {
        type: String,
        default: 'size',
      },

      typeField: {
        type: String,
        default: 'type',
      },

      buffer: {
        type: Number,
        default: 200,
      },

      prerender: {
        type: Number,
        default: 0,
      },
    },

    data: () => ({
      pool: [],
      totalSize: 0,
      ready: false,
      pos: {},
    }),

    computed: {
      sizes() {
        if (this.itemSize === null) {
          const sizes = {
            '-1': { accumulator: 0 },
          };
          const { items } = this;
          const field = this.sizeField;
          const { minItemSize } = this;
          let computedMinSize = 10000;
          let accumulator = 0;
          let current;
          for (let i = 0, l = items.length; i < l; i++) {
            current = items[i][field] || minItemSize;
            if (current < computedMinSize) {
              computedMinSize = current;
            }
            accumulator += current;
            sizes[i] = { accumulator, size: current };
          }
          // eslint-disable-next-line
        this.$_computedMinItemSize = computedMinSize
          return sizes;
        }
        return [];
      },

      simpleArray,
    },

    watch: {
      items() {
        this.updateVisibleItems(true);
      },
    },

    created() {
      this.$_startIndex = 0;
      this.$_endIndex = 0;
      this.$_views = new Map();
      this.$_unusedViews = new Map();
      this.$_scrollDirty = false;
      this.$_lastUpdateScrollPosition = 0;

      // In SSR mode, we also prerender the same number of item for the first render
      // to avoir mismatch between server and client templates
      if (this.prerender) {
        this.$_prerender = true;
        this.updateVisibleItems(false);
      }
    },

    mounted() {
      this.$refs.scroller.addEventListener('mousedown', this.mouseDownHandler, { passive: true });

      this.$nextTick(() => {
        // In SSR mode, render the real number of visible items
        this.$_prerender = false;
        this.updateVisibleItems(true);
        this.ready = true;
      });
    },

    beforeDestroy() {
      this.$refs.scroller.removeEventListener('mousedown', this.mouseDownHandler);
    },

    methods: {
      addView(pool, index, item, key, type) {
        const view = {
          item,
          position: 0,
        };
        const nonReactive = {
          id: uid++,
          index,
          used: true,
          key,
          type,
        };
        Object.defineProperty(view, 'nr', {
          configurable: false,
          value: nonReactive,
        });
        pool.push(view);
        return view;
      },

      unuseView(view, fake = false) {
        const unusedViews = this.$_unusedViews;
        const { type } = view.nr;
        let unusedPool = unusedViews.get(type);
        if (!unusedPool) {
          unusedPool = [];
          unusedViews.set(type, unusedPool);
        }
        unusedPool.push(view);
        if (!fake) {
          view.nr.used = false;
          view.position = -9999;
          this.$_views.delete(view.nr.key);
        }
      },

      handleScroll() {
        if (!this.$_scrollDirty) {
          this.$_scrollDirty = true;
          requestAnimationFrame(() => {
            this.$_scrollDirty = false;
            const { continuous } = this.updateVisibleItems(false, true);

            // It seems sometimes chrome doesn't fire scroll event :/
            // When non continous scrolling is ending, we force a refresh
            if (!continuous) {
              clearTimeout(this.$_refreshTimout);
              this.$_refreshTimout = setTimeout(this.handleScroll, 100);
            }
          });
        }
      },

      updateVisibleItems(checkItem, checkPositionDiff = false) {
        const { itemSize } = this;
        const minItemSize = this.$_computedMinItemSize;
        const { typeField } = this;
        const keyField = this.simpleArray ? null : this.keyField;
        const { items } = this;
        const count = items.length;
        const { sizes } = this;
        const views = this.$_views;
        const unusedViews = this.$_unusedViews;
        const { pool } = this;
        let startIndex; let
          endIndex;
        let totalSize;

        if (!count) {
          startIndex = endIndex = totalSize = 0;
        } else if (this.$_prerender) {
          startIndex = 0;
          endIndex = this.prerender;
          totalSize = null;
        } else {
          const scroll = this.getScroll();

          // Skip update if use hasn't scrolled enough
          if (checkPositionDiff) {
            let positionDiff = scroll.start - this.$_lastUpdateScrollPosition;
            if (positionDiff < 0) positionDiff = -positionDiff;
            if ((itemSize === null && positionDiff < minItemSize) || positionDiff < itemSize) {
              return {
                continuous: true,
              };
            }
          }
          this.$_lastUpdateScrollPosition = scroll.start;

          const { buffer } = this;
          scroll.start -= buffer;
          scroll.end += buffer;

          // Variable size mode
          if (itemSize === null) {
            let h;
            let a = 0;
            let b = count - 1;
            let i = ~~(count / 2);
            let oldI;

            // Searching for startIndex
            do {
              oldI = i;
              h = sizes[i].accumulator;
              if (h < scroll.start) {
                a = i;
              } else if (i < count - 1 && sizes[i + 1].accumulator > scroll.start) {
                b = i;
              }
              i = ~~((a + b) / 2);
            } while (i !== oldI);
            i < 0 && (i = 0);
            startIndex = i;

            // For container style
            totalSize = sizes[count - 1].accumulator;

            // Searching for endIndex
            for (endIndex = i; endIndex < count && sizes[endIndex].accumulator < scroll.end; endIndex++);
            if (endIndex === -1) {
              endIndex = items.length - 1;
            } else {
              endIndex++;
              // Bounds
              endIndex > count && (endIndex = count);
            }
          } else {
            // Fixed size mode
            startIndex = ~~(scroll.start / itemSize);
            endIndex = Math.ceil(scroll.end / itemSize);

            // Bounds
            startIndex < 0 && (startIndex = 0);
            endIndex > count && (endIndex = count);

            totalSize = count * itemSize;
          }
        }

        if (endIndex - startIndex > config.itemsLimit) {
          this.itemsLimitError();
        }

        this.totalSize = totalSize;

        let view;

        const continuous = startIndex <= this.$_endIndex && endIndex >= this.$_startIndex;

        if (this.$_continuous !== continuous) {
          if (continuous) {
            views.clear();
            unusedViews.clear();
            for (let i = 0, l = pool.length; i < l; i++) {
              view = pool[i];
              this.unuseView(view);
            }
          }
          this.$_continuous = continuous;
        } else if (continuous) {
          for (let i = 0, l = pool.length; i < l; i++) {
            view = pool[i];
            if (view.nr.used) {
              // Update view item index
              if (checkItem) {
                view.nr.index = items.findIndex(
                  item => (keyField ? item[keyField] === view.item[keyField] : item === view.item),
                );
              }

              // Check if index is still in visible range
              if (
                view.nr.index === -1
                || view.nr.index < startIndex
                || view.nr.index >= endIndex
              ) {
                this.unuseView(view);
              }
            }
          }
        }

        const unusedIndex = continuous ? null : new Map();

        let item; let type; let
          unusedPool;
        let v;
        for (let i = startIndex; i < endIndex; i++) {
          item = items[i];
          const key = keyField ? item[keyField] : item;
          if (key == null) {
            throw new Error(`Key is ${key} on item (keyField is '${keyField}')`);
          }
          view = views.get(key);

          if (!itemSize && !sizes[i].size) {
            if (view) this.unuseView(view);
            continue;
          }

          // No view assigned to item
          if (!view) {
            type = item[typeField];
            unusedPool = unusedViews.get(type);

            if (continuous) {
              // Reuse existing view
              if (unusedPool && unusedPool.length) {
                view = unusedPool.pop();
                view.item = item;
                view.nr.used = true;
                view.nr.index = i;
                view.nr.key = key;
                view.nr.type = type;
              } else {
                view = this.addView(pool, i, item, key, type);
              }
            } else {
              // Use existing view
              // We don't care if they are already used
              // because we are not in continous scrolling
              v = unusedIndex.get(type) || 0;

              if (!unusedPool || v >= unusedPool.length) {
                view = this.addView(pool, i, item, key, type);
                this.unuseView(view, true);
                unusedPool = unusedViews.get(type);
              }

              view = unusedPool[v];
              view.item = item;
              view.nr.used = true;
              view.nr.index = i;
              view.nr.key = key;
              view.nr.type = type;
              unusedIndex.set(type, v + 1);
              v++;
            }
            views.set(key, view);
          } else {
            view.nr.used = true;
            view.item = item;
          }

          // Update position
          if (itemSize === null) {
            view.position = sizes[i - 1].accumulator;
          } else {
            view.position = i * itemSize;
          }
        }

        this.$_startIndex = startIndex;
        this.$_endIndex = endIndex;

        // After the user has finished scrolling
        // Sort views so text selection is correct
        clearTimeout(this.$_sortTimer);
        this.$_sortTimer = setTimeout(this.sortViews, 300);

        return {
          continuous,
        };
      },

      getScroll() {
        const { $el: el } = this;
        const scrollState = {
          start: el.scrollTop,
          end: el.scrollTop + el.clientHeight,
        };

        return scrollState;
      },

      itemsLimitError() {
        setTimeout(() => {
          console.log('It seems the scroller element isn\'t scrolling, so it tries to render all the items at once.', 'Scroller:', this.$el);
          console.log('Make sure the scroller has a fixed height (or width) and \'overflow-y\' (or \'overflow-x\') set to \'auto\' so it can scroll correctly and only render the items visible in the scroll viewport.');
        });
        throw new Error('Rendered items limit reached');
      },

      sortViews() {
        this.pool.sort((viewA, viewB) => viewA.nr.index - viewB.nr.index);
      },

      mouseDownHandler(e) {
        this.pos = {
          // The current scroll
          left: this.$refs.scroller.scrollLeft,
          top: this.$refs.scroller.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener('mousemove', this.mouseMoveHandler, { passive: true });
        document.addEventListener('mouseup', this.mouseUpHandler, { passive: true });

        // Change the cursor and prevent user from selecting the text
        this.$refs.scroller.style.cursor = 'grabbing';
        this.$refs.scroller.style.userSelect = 'none';
      },

      mouseMoveHandler(e) {
        // How far the mouse has been moved
        const dx = e.clientX - this.pos.x;
        const dy = e.clientY - this.pos.y;

        // Scroll the element
        this.$refs.scroller.scrollTop = this.pos.top - dy;
        this.$refs.scroller.scrollLeft = this.pos.left - dx;
      },

      mouseUpHandler() {
        document.removeEventListener('mousemove', this.mouseMoveHandler);
        document.removeEventListener('mouseup', this.mouseUpHandler);

        this.$refs.scroller.style.cursor = 'grab';
        this.$refs.scroller.style.removeProperty('user-select');
      },
    },
  };
</script>
