<template>
  <div
    class="d-flex flex-column full-height"
  >
    <div>
      <v-tabs
        show-arrows
        class="backgroundAccent"
      >
        <v-tab
          v-for="(tab, i) in navItems"
          :key="i"
          :to="tab.to"
        >
          {{ tab.title }}
        </v-tab>

        <v-tooltip
          bottom
          content-class="pa-0 force-full-opacity"
        >
          <template #activator="{ on: tooltip }">
            <div
              class="d-flex justify-center align-center caption-xs warning--text px-6"
              v-on="tooltip"
            >
              <v-icon
                small
                left
                style="opacity: 0.8;"
              >
                info
              </v-icon>
            </div>
          </template>

          <v-card
            dark
            max-width="400px"
          >
            <!--<v-card-text>-->
            <v-alert
              icon="warning"
              type="warning"
              dense
              text
              border="left"
              class="caption mb-0 py-6 px-6"
            >
              <span v-translate>
                Please note that the project planning views have been built for
                a 100% browser zoom level.
              </span>
              <br><br>
              <span v-translate>
                If you do use the browser zoom feature, table cells may not
                align correctly on all zoom levels.
              </span>
            </v-alert>
            <!--</v-card-text>-->
          </v-card>
        </v-tooltip>

        <v-spacer />

        <v-tab
          disabled
          style="opacity: 1;"
        >
          <div>
            <div
              v-if="savingSomething"
            >
              <span
                v-translate
                class="caption-xs white--text font-weight-bold text-uppercase"
              >
                Saving...
              </span>

              <v-progress-circular
                indeterminate
                color="primary lighten-3"
                size="16"
                width="2"
                class="ml-2"
              />
            </div>

            <div
              v-else
            >
              <span
                v-translate
                class="caption-xs font-weight-bold text-uppercase"
              >
                Saved
              </span>

              <v-icon
                color="primary"
                class="ml-2"
                size="20"
              >
                cloud_done
              </v-icon>
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <!-- project editor subviews -->
    <div
      class="flex-grow-1"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        showWorkloadSidePanel: 'utils/showWorkloadSidePanel',
      }),

      savingSomething() {
        return this.$wait.is([
          'saving project',

          'saving workPackage',
          'batch saving workPackages',
          'destroying workPackage',

          'saving task',
          'batch saving tasks',
          'destroying task',

          'saving team',
          'batch saving teams',
          'destroying team',

          'saving passage',
          'destroying passage',

          'saving space',
          'batch saving spaces',
          'destroying space',

          'regenerating tacts',

          'reloading project data',
        ]);
      },

      navItems() {
        return [
          {
            title: this.$pgettext('Project editor nav item title', 'Project basics'),
            icon: 'check',
            color: 'success',
            textColor: 'success--text',
            to: {
              name: 'project-info',
              params: {
                projectId: this.$projectId,
                versionId: 1,
              },
            },
          },
          {
            title: this.$pgettext('Project editor nav item title', 'Passages'),
            icon: 'check',
            color: 'success',
            textColor: 'success--text',
            to: {
              name: 'project-passages',
              params: {
                projectId: this.$projectId,
                versionId: 1,
              },
            },
          },
          {
            title: this.$pgettext('Project editor nav item title', 'Time & space'),
            icon: 'clear',
            color: 'error',
            textColor: 'error--text',
            to: {
              name: 'project-space-time',
              params: {
                projectId: this.$projectId,
                versionId: 1,
              },
            },
          },
          {
            title: this.$pgettext('Project editor nav item title', 'Resource demand'),
            icon: 'clear',
            color: 'error',
            textColor: 'error--text',
            to: {
              name: 'project-workload',
              params: {
                projectId: this.$projectId,
                versionId: 1,
              },
            },
          },
        ];
      },
    },
  };
</script>

<style lang="scss">
.force-full-opacity {
  opacity: 1 !important;
}
</style>
